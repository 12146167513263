import React, { useState, useEffect,useRef  } from 'react';
import { Button, message } from 'antd';
import { QRCodeCanvas } from 'qrcode.react';
import { Carousel } from 'antd'; // Ant Design Carousel
import profilepic from  '../components/photos/nfcpie.png'
import './ButtonSheet.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ButtonSheetModal = ({ userData }) => {
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(null);
  const sheetRef = useRef(null);
  const startY = useRef(null);
  const isDragging = useRef(false);
  

  const menuItems = [
    ...(userData.ibans && userData.ibans.length > 0 ? [{ type: 'banka', label: 'Banka Bilgileri', icon: 'fas fa-university fa-xl' }] : []),
    ...(userData.events && userData.events.length > 0 ? [{ type: 'galeri', label: 'Galeri', icon: 'fas fa-images fa-xl' }] : []),
    { type: 'paylas', label: 'Paylaş', icon: 'fas fa-share-alt fa-xl' },
    ...(userData.qrCodes && userData.qrCodes.length > 0 ? [{ type: 'QR Kod', label: 'QR Kod', icon: 'fas fa-qrcode fa-xl' }] : []),
    ...(userData.addresses && userData.addresses.find(addr => addr.type === 'INVOICE') ? [{ type: 'fatura', label: 'Fatura Bilgileri', icon: 'fas fa-file-invoice fa-xl' }] : []),

  ];
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.bottom-sheet') && !event.target.closest('.sheet-button')) {
        closeMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const openMenu = (menuType) => {
    if (menuType === 'paylas') {
      handleShare(); // Paylaşma işlemini başlat
      return; // Modal açılmasını engelle
    }
  
    setCurrentMenu(menuType);
    setVisibleMenu(true);
  };

  const closeMenu = () => {
    if (sheetRef.current) {
      sheetRef.current.classList.add('closing'); // Kapanma animasyonu eklenir
      setTimeout(() => {
        setVisibleMenu(false); // Animasyon tamamlandıktan sonra menü kapatılır
        setCurrentMenu(null);
        if (sheetRef.current) {
          sheetRef.current.classList.remove('closing'); // Sınıf kaldırılır
        }
      }, 300); // Animasyon süresiyle uyumlu
    }
  };

  useEffect(() => {
    const preventScroll = (e) => {
      e.preventDefault();
    };
  
    if (visibleMenu) {
      document.body.style.overflow = 'hidden'; // Sayfa kaydırmayı engelle
      document.addEventListener('touchmove', preventScroll, { passive: false });
    } else {
      document.body.style.overflow = ''; // Sayfa kaydırmayı eski haline getir
      document.removeEventListener('touchmove', preventScroll);
    }
  
    return () => {
      document.body.style.overflow = ''; // Modal kapandığında temizleme
      document.removeEventListener('touchmove', preventScroll);
    };
  }, [visibleMenu]);
  

  useEffect(() => {
    const handleTouchStart = (e) => {
      if (sheetRef.current && sheetRef.current.contains(e.target)) {
        startY.current = e.touches[0].clientY;
        isDragging.current = true;
      }
    };
  
    const handleTouchMove = (e) => {
      if (!isDragging.current) return;
  
      const currentY = e.touches[0].clientY;
      const deltaY = currentY - startY.current;
  
      if (deltaY > 0) {
        e.preventDefault(); // Varsayılan kaydırmayı engelle
        if (sheetRef.current) {
          sheetRef.current.style.transform = `translateY(${deltaY}px)`;
        }
      }
    };

    
  
    const handleTouchEnd = () => {
      if (!isDragging.current) return;
      isDragging.current = false;
  
      if (sheetRef.current) {
        const finalTransform = parseFloat(sheetRef.current.style.transform.replace('translateY(', '').replace('px)', '')) || 0;
        if (finalTransform > 100) {
          closeMenu();
        } else {
          sheetRef.current.style.transform = 'translateY(0)';
        }
      }
    };
  
    document.addEventListener('touchstart', handleTouchStart, { passive: false });
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: `${userData?.user?.firstName} ${userData?.user?.lastName} - Digital Business Card`,
        text: 'İletişim bilgileri',
        url: window.location.href,
      })
      .then(() => {
        console.log('Başarıyla paylaşıldı');
        closeMenu(); // Menü kapatılıyor
      })
      .catch((error) => {
        console.error('Paylaşım başarısız:', error);
        closeMenu(); // Menü kapatılıyor
      });
    } else {
      alert('Paylaşım desteği mevcut değil.');
      closeMenu()
    }
  }; 
  

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success('Kopyalama İşlemi Başarılı');
    } catch (error) {
      console.error('Kopyalama hatası:', error);
      message.error('Kopyalama Başarısız');
    }
  };
  
  const renderMenuContent = () => {
    switch (currentMenu) {
      case 'QR Kod':
        return renderQRCode();
      case 'galeri':
        return renderGallery();
      case 'banka':
        return renderBankInfo();
        case 'fatura':
        return renderInvoiceAddress();
      default:
        // return renderDefaultMenu();
    }
  };

  const renderInvoiceAddress = () => {
    const sliderSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true, // Yüksekliğe göre otomatik ayarlama
    };
  
    return (
      <div style={{ position: "relative", height: "100%" }}>
        <div className="menu-header">
          <h3>Fatura Bilgileri</h3>
        </div>
        <div style={{ position: "relative", paddingBottom: "32px" }}>
          <Slider {...sliderSettings}>
            {userData.addresses && userData.addresses.length > 0 ? (
              userData.addresses
                .filter((addr) => addr.type === "INVOICE")
                .map((invoiceAddress) => (
                  <div key={invoiceAddress.id} className="iban-slide">
                    <div style={{ marginBottom: "10px" }}>
                      <strong style={{ color: "black" }}>Adres:</strong> {invoiceAddress.fullAddress}
                    </div>
                    <div style={{ marginBottom: "10px", color:"#0d6efd" }}>
                      <strong style={{ color: "black" }}>Vergi Numarası:</strong> {invoiceAddress.description}
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <strong style={{ color: "black" }}>Vergi Dairesi:</strong> {invoiceAddress.taxNumber}
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <strong style={{ color: "#da4453" }}>Mersis Numarası:</strong> {invoiceAddress.mersisNumber}
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <strong style={{ color: "#da4453" }}>Ticaret Sicil No:</strong> {invoiceAddress.tradeRegistryNumber}
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <strong style={{ color: "#da4453" }}>KEP Adresi:</strong> {invoiceAddress.kepAddress}
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <strong style={{ color: "#da4453" }}>UETS Adresi:</strong> {invoiceAddress.uetsAddress}
                    </div>
                    <button
                      onClick={() =>
                        copyToClipboard(
                          `Vergi Numarası: ${invoiceAddress.description}\nAdres: ${invoiceAddress.fullAddress}\nVergi Dairesi: ${invoiceAddress.taxNumber}\nMersis Numarası: ${invoiceAddress.mersisNumber}\nTicaret Sicil No: ${invoiceAddress.tradeRegistryNumber}\nKEP Adresi: ${invoiceAddress.kepAddress}\nUETS Adresi: ${invoiceAddress.uetsAddress}`
                        )
                      }
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        backgroundColor: "#7db1b1",
                        color: "white",
                        border: "none",
                        borderRadius: "10px",
                        padding: "12px 16px",
                        fontSize: "16px",
                        width: "80%",
                        maxWidth: "400px",
                        display: "block",
                        textAlign: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      title="Fatura bilgilerini kopyala"
                    >
                      <i className="fas fa-copy" style={{ marginRight: "5px" }}></i>{" "}
                      Fatura bilgilerini kopyala
                    </button>
                  </div>
                ))
            ) : (
              <p>Fatura bilgisi bulunmamaktadır.</p>
            )}
          </Slider>
        </div>
      </div>
    );
  };
  

  const renderQRCode = () => {
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true, // Yüksekliğe göre otomatik ayarlama
    };

    return (
        <div style={{ position: "relative", height: "100%" }}>
            <div className="menu-header">
                <h3>QR Kodları</h3>
            </div>
            <div style={{ position: "relative", paddingBottom: "32px" }}>
                <Slider {...sliderSettings}>
                    {userData.qrCodes && userData.qrCodes.length > 0 ? (
                        userData.qrCodes.map((qrCode) => (
                            <div key={qrCode.id} className="qr-code-slide">
                                <p>
                                    <h1 style={{ color: "#da4453", fontSize: "15px" }}>
                                        {qrCode.title}
                                    </h1>
                                </p>
                                <div
                                    className="qr-code-container"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: "20px", // Alttan boşluk
                                    }}
                                >
                                    <div style={{ position: "relative", width: 200, height: 200 }}>
                                        <QRCodeCanvas
                                            value={qrCode.link}
                                            size={200}
                                            level="H"
                                            imageSettings={{
                                                src: profilepic,
                                                x: 75, // QR kod içinde resmin x pozisyonu
                                                y: 75, // QR kod içinde resmin y pozisyonu
                                                height: 50, // Resmin yüksekliği
                                                width: 50, // Resmin genişliği
                                                excavate: true,
                                            }}
                                        />
                                    </div>
                                </div>
                                <p>
                                    <button
                                        onClick={() => copyToClipboard(qrCode.link)}
                                        style={{
                                            cursor: "pointer",
                                            marginTop: "10px",
                                            backgroundColor: "#7db1b1",
                                            color: "white",
                                            border: "none",
                                            borderRadius: "10px",
                                            padding: "12px 16px",
                                            fontSize: "16px",
                                            width: "80%", // Genişliği %80 yapar
                                            maxWidth: "400px", // Maksimum genişlik sınırı
                                            display: "block", // Blok elemanı olarak davranır
                                            textAlign: "center", // Yazıyı ortalar
                                            marginLeft: "auto", // Ortalamak için kullanılır
                                            marginRight: "auto", // Ortalamak için kullanılır
                                        }}
                                        title="Kopyala"
                                    >
                                        <i
                                            className="fas fa-copy"
                                            style={{ marginRight: "5px" }}
                                        ></i>{" "}
                                        QR Kod Linkini Kopyala
                                    </button>
                                </p>
                            </div>
                        ))
                    ) : (
                        <p>QR Kod bilgisi bulunmamaktadır.</p>
                    )}
                </Slider>
            </div>
        </div>
    );
};

  const renderGallery = () => (
    <div className="bottom-sheet-gallery-modal">
      <div className="menu-header">
        <h3>Galeri</h3>
      </div>
      <Carousel>
        {userData.events.map((event, index) => (
          <div 
            key={index} 
            className="carousel-item" 
            style={{ 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "center", 
              justifyContent: "center",
              textAlign: "center", // İçeriklerin ortalanmasını sağlar
            }}
          >
            <img
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${event.images[0]}`}
              alt={`Gallery item ${index + 1}`}
              className="gallery-image"
              style={{ 
                objectFit: "contain", 
                maxWidth: "100%", 
                maxHeight: "300px",
                margin: "0 auto", // Resmi tam ortaya hizalar
                borderRadius:"8px",
                
              }}
            />
            <p>
              <strong>Başlangıç Tarihi:</strong>  {new Date(event.startDate).toLocaleDateString()}
            </p>
            <p>
              <strong>Bitiş Tarihi:</strong> {new Date(event.endDate).toLocaleDateString()}
            </p>
          </div>
        ))}
      </Carousel>
    </div>
  );
  
  

  const renderBankInfo = () => {
    const sliderSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true, // Yüksekliğe göre otomatik ayarlama
    };
  
    return (
      <div style={{ position: "relative", height: "100%" }}>
        <div className="menu-header">
          <h3>Banka Bilgileri</h3>
        </div>
        <div style={{ position: "relative", paddingBottom: "32px" }}>
          <Slider {...sliderSettings}>
            {userData.ibans && userData.ibans.length > 0 ? (
              userData.ibans.map((iban) => (
                <div key={iban.id} className="iban-slide">
                  <p>
                    <h1 style={{ color: "#da4453", fontSize: "15px" }}>
                      {iban.title}
                    </h1>
                  </p>
                  {iban.description && (
                    <p>
                      <strong>{iban.name} Ad Soyad</strong> 
                      <span
                        className="copy-icon"
                        onClick={() => copyToClipboard(iban.name)}
                        style={{ cursor: "pointer", marginLeft: "10px", color: "#7db1b1" }}
                        title="Kopyala"
                      >
                        <i className="fas fa-copy"></i>
                      </span>
                    </p>
                  )}
                  <p>
                    <div
                      className="line-height-l font-16"
                      style={{ color: "#6C6C6C", fontSize: "15px" }}
                    >
                      {iban.iban}
                    </div>
                    <button
                      onClick={() => copyToClipboard(iban.iban)}
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        backgroundColor: "#7db1b1",
                        color: "white",
                        border: "none",
                        borderRadius: "10px",
                        padding: "12px 16px",
                        fontSize: "16px",
                        width: "80%", // Genişliği %100 yapar
                        maxWidth: "400px", // Maksimum genişlik sınırı
                        display: "block", // Blok elemanı olarak davranır
                        textAlign: "center", // Yazıyı ortalar
                        marginLeft: "auto", // Ortalamak için kullanılır
                        marginRight: "auto", // Ortalamak için kullanılır
                      }}
                      title="Kopyala"
                    >
                      <i
                        className="fas fa-copy"
                        style={{ marginRight: "5px" }}
                      ></i>{" "}
                      IBAN Kopyala
                    </button>
                  </p>


                </div>
              ))
            ) : (
              <p>IBAN bilgisi bulunmamaktadır.</p>
            )}
          </Slider>
        </div>
      </div>
    );
  };
  


  return (
    <div className="button-sheet">
      {menuItems.map((item) => (
        <Button key={item.type} className="sheet-button" onClick={() => openMenu(item.type)}>
          <div className="icon-wrapper">
            <i className={item.icon}></i>
          </div>
          <div className="button-label">{item.label}</div>
        </Button>
      ))}

{visibleMenu && (
  <div className="overlay">
    <div ref={sheetRef} className={`bottom-sheet`}>
      {renderMenuContent()}
    </div>
  </div>
)}
    </div>
  );
};


export default ButtonSheetModal;
