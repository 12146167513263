/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from "react";
import { Card, Spin, Avatar, Typography } from "antd";
import ButtonGroup from "./components/Buttons";
import ModalComponent from "./components/Modal";
import axios from "axios";
import ButtonSheet from "./components/ButtonSheet";
import defaultBG from "./components/photos/nfcpie.png"
import defaultAvatar from "./components/photos/avatar.png"


const DigitalCard = () => {
  const [theme, setTheme] = useState(null);
  const [isCSSLoaded, setIsCSSLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalContent, setModalContent] = useState({});
  const [visible, setVisible] = useState(false);
  const [userData, setUserData] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;
  const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL

  const getSerialFromUrl = () => {
    const pathname = window.location.pathname;
    const serialNumber = pathname.slice(-9);
    return serialNumber;
  };

  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const serialNumber = getSerialFromUrl();
      const response = await axios.get(`${API_URL}/${serialNumber}`);
      
      if (!response.data?.data) {
        // userData boşsa yönlendir
        window.location.href = "https://panel.nfcpie.com/";
        return;
      }
  
      setUserData(response.data.data);
  
      // Set theme from API response
      if (response.data.data.user.selectedTheme) {
        setTheme(response.data.data.user.selectedTheme);
      } else {
        setTheme(2); // Default theme if none is selected
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setTheme(3); // Set default theme on error
  
      // Hata durumunda yönlendirme yap
      window.location.href = "https://panel.nfcpie.com/";
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Only load CSS after theme is set
    if (theme === null) return;

    setIsCSSLoaded(false);
    let cssModule;

    if (theme === 1) {
      cssModule = import("../src/styles/App.css");
    } else if (theme === 2) {
      cssModule = import("../src/styles/App3.css");
    } else if (theme === 3) {
      cssModule = import("../src/styles/App4.css");
    } else if (theme === 4) {
      cssModule = import("../src/styles/App5.css");
    } else if (theme === 5) {
      cssModule = import("../src/styles/App6.css");
    } else if (theme === 6) {
      cssModule = import("../src/styles/App2.css");
    }else if (theme === 7) {
      cssModule = import("../src/styles/App7.css");
    }else if (theme === 8) {
      cssModule = import("../src/styles/App8.css");
    }else if (theme === 9) {
      cssModule = import("../src/styles/App9.css");
    }

    if (cssModule) {
      cssModule
        .then(() => {
          setIsCSSLoaded(true);
          setIsLoading(false);
          
        })
        .catch(() => {
          console.error("CSS yüklenirken hata oluştu.");
          setIsCSSLoaded(true);
          setIsLoading(false);
        });
    } else {
      console.error("Geçersiz CSS modülü.");
      setIsLoading(false);
    }
  }, [theme]);

  const handleButtonClick = (content) => {
    setModalContent(content);
    setVisible(true);
  };

  const handleOk = () => setVisible(false);
  const handleCancel = () => setVisible(false);

  const { Text } = Typography;

  if (isLoading || !isCSSLoaded || !userData) {
    return (
      <Spin tip="Yükleniyor...">
        <div style={{ height: "100vh" }} />
      </Spin>
    );
  }

  return (
    <div className="container">
      <Card style={{borderRadius:"15px 15px 15px 15px"}}>
        <div className="profile-section-back" style={{ backgroundImage: `url(${IMAGE_BASE_URL}${userData?.user?.backgroundImage || defaultBG})`}}>
          <div className="profile-section">
            <Avatar
              size={80}
              src={userData?.user?.profileImage ? IMAGE_BASE_URL + userData.user.profileImage : defaultAvatar}
              alt="Profile"
              style={{ marginTop: "30px", backgroundColor:"white" }}
            />
            <Text className="isim-text" strong>
              {userData?.user.firstName} {userData?.user?.lastName}
            </Text>
            <Text className="unvan-text">{userData?.user?.jobTitle} </Text>
            <Text className="firma-text"> {userData?.user?.company ? `/ ${userData.user.company}` : ''}</Text>          
            </div>
        </div>
        <div style={{marginTop:"150px"}}>
          <ButtonGroup userData={userData} handleButtonClick={handleButtonClick} />
        </div>
        <ModalComponent
          userData={userData}
          open={visible}
          modalContent={modalContent}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
        <ButtonSheet userData={userData} />
      </Card>
    </div>
  );
};

export default DigitalCard;